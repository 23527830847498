import { exists } from "../../_helper/util.helper";

/**
 * The model that holds the configurable color scheme for the website
 */
export class ThemeModel {

  public dark: boolean = null;
  public darkBG: string = null;
  public lightBG: string = null;
  public toolbar: string = null;
  public toolbarText: string = null;
  public primary: string = null;
  public accent: string = null;
  public warn: string = null;

  constructor(model?: Partial<ThemeModel>) {
    if (model) {
      if (exists(model.dark)) this.dark = model.dark;
      if (model.darkBG) this.darkBG = model.darkBG;
      if (model.lightBG) this.lightBG = model.lightBG;
      if (model.toolbar) this.toolbar = model.toolbar;
      if (model.primary) this.primary = model.primary;
      if (model.accent) this.accent = model.accent;
      if (model.warn) this.warn = model.warn;
    }
  }
}
