import { buildAddresses, exists, formatName } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { LookupModel } from "../metadata/lookup.model";

/**
 * Model for the Listing data
 */
export class ListingModel extends BaseModel {

  public permissions: {
    Create?: boolean;
    Read?: boolean;
    Update?: boolean;
    Delete?: boolean;
    ChangeStatus?: boolean;
    DraftCreate?: boolean;
    DraftRead?: boolean;
    DraftUpdate?: boolean;
    DraftDelete?: boolean;
    DraftActivate?: boolean;
    DocsCreate?: boolean;
    DocsDelete?: boolean;
    DocsRead?: boolean;
    DocsUpdate?: boolean;
    PhotosCreate?: boolean;
    PhotosDelete?: boolean;
    PhotosRead?: boolean;
    PhotosUpdate?: boolean;
    HistoryRead?: boolean;
  } = {};
  public statusChanges: string[] = [];

  public id: number = null;
  public customerName: string = null;
  public propertyType: string = null;
  public transactionType: string = null;
  public propertySubType: string = null;
  public streetNumber: string = null;
  public streetDirPrefix: string = null;
  public streetName: string = null;
  public streetSuffix: string = null;
  public streetDirSuffix: string = null;
  public unitNumber: string = null;
  public city: string = null;
  public stateOrProvince: string = null;
  public postalCode: string = null;
  public country: string = null;
  public latitude: number = null;
  public longitude: number = null;
  public listingId: string = null;
  public listingKey: string = null;
  public standardStatus: string = null;
  public mlsStatus: string = null;
  public originatingSystemId: string = null;
  public originatingSystemKey: string = null;
  public originatingSystemName: string = null;
  public sourceSystemId: string = null;
  public sourceSystemKey: string = null;
  public sourceSystemName: string = null;
  public listPrice: number = null;
  public closePrice: number = null;
  public listAgentKey: string = null;
  public listOfficeKey: string = null;
  public listTeamKey: string = null;
  public listingContractDate: Date = null;
  public expirationDate: Date = null;
  public photosChangeTimestamp: Date = null;
  public documentsChangeTimestamp: Date = null;
  public mediaChangeTimestamp: Date = null;
  public modificationTimestamp: Date = null;
  public originalEntryTimestamp: Date = null;
  public changedByMemberKey: any = null;
  public createDate: Date = null;
  public modifyDate: Date = null;
  public searchAddress: string = null;
  public contractStatus: string = null;
  public mainOfficeKey: string = null;
  public coListAgentKey: string = null;
  public coListOfficeKey: string = null;
  public listAgentLastName: string = null;
  public listAgentFirstName: string = null;

  //not in dd-dao, display purposes only
  public address1: string = null;
  public address2: string = null;
  public fullAddress: string = null;
  public listAgentFullName: string = null;
  public thumbnailUrl: string = null;
  public icon: string = null;

  constructor(model?: Partial<ListingModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model: Partial<ListingModel>, ...exclude: string[]) {
    super.overwrite(model, 'icon', ...exclude);
    if (!this.icon) this.icon = model?.icon || this.randomIcon();
    this.buildAddress();
  }

  /**
   * Generates a random icon to be displayed if the listing does not have a thumbnail
   */
  private randomIcon() {
    return randomIcons[Math.floor(Math.random() * randomIcons.length)];
  }

  /**
   * Uses the properties on the listing object to generate the address strings (line 1, 2, and fullAddress)
   */
  public buildAddress(lookups?: LookupModel[]) {
    let addresses = buildAddresses(
      this.streetNumber, this.streetName, this.streetSuffix, this.streetDirSuffix, this.unitNumber,
      this.city, this.stateOrProvince, this.postalCode, '', lookups
    );
    for (let key in addresses) {
      this[key] = addresses[key];
    }
  }
}

//random material icons as a placeholder for when we don't have a photo
//artificially weighted so some show up more often than others
const randomIcons: string[] = [
  //5
  'apartment', 'apartment', 'apartment', 'apartment', 'apartment',
  'cottage',   'cottage',   'cottage',   'cottage',   'cottage',
  'house',     'house',     'house',     'house',     'house',
  'store',     'store',     'store',     'store',     'store',
  'villa',     'villa',     'villa',     'villa',     'villa',
  'gite',      'gite',      'gite',      'gite',      'gite',
  //4
  'other_houses', 'other_houses', 'other_houses', 'other_houses',
  //3
  'holiday_village', 'holiday_village', 'holiday_village',
  'maps_home_work',  'maps_home_work',  'maps_home_work',
  'storefront',      'storefront',      'storefront',
  //2
  'corporate_fare', 'corporate_fare',
  'location_city',  'location_city',
  'house_siding',   'house_siding',
  'business',       'business',
  //1
  'night_shelter',
  'warehouse',
  'castle',
];
