import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { EnvironmentEnum } from "../../environments/environment.base";
import { HomeEnum } from "../_enum/settings/home.enum";
import { AppDialog, AppDialogConfig } from "../_shared/app/dialog/app.dialog";
import { LocalStorage } from "./local-storage.service";
import tinycolor from 'tinycolor2';
import { ThemeModel } from '../_model/settings/theme.model';
import { SettingsModel } from "../_model/settings/settings.model";
import { HomeModel } from "../_model/settings/home.model";
import { IDBTable, IndexedDBService } from "./indexed-db.service";
import { HttpService } from "./http.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { TableModel } from "../_model/settings/home/table.model";
import { UserModel } from "../_model/member/user.model";
import { KeysModel } from "../_model/settings/keys.model";
import { UrlsModel } from "../_model/settings/urls.model";
import { MenuModel } from "../_model/settings/menu.model";
import { MiscModel } from "../_model/settings/misc.model";

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends HttpService {

  private table = IDBTable.Settings as const;
  private url = environment.apiUrl + 'uiSettings';
  private user: UserModel = null;

  private settings: SettingsModel = new SettingsModel();

  //Homepage
  private home$ = new BehaviorSubject<HomeModel>(this.settings?.home);
  public homeTabIndex: number = 0;

  //Menu
  private menu$ = new BehaviorSubject<MenuModel>(this.settings?.menu);

  //Theme
  private theme$ = new BehaviorSubject<ThemeModel>(this.settings?.theme);

  //Keys
  private keys$ = new BehaviorSubject<KeysModel>(this.settings?.keys);

  //Urls
  private urls$ = new BehaviorSubject<UrlsModel>(this.settings?.urls);

  //Misc
  private misc$ = new BehaviorSubject<MiscModel>(this.settings?.misc);

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private idb: IndexedDBService
   ) {
    super(http, userService, router, dialog, localStorage);
    this.loadUser();
    this.loadSettings();
  }

  private loadUser() {
    this.userService.getUserSub().subscribe(_user => {
      this.user = _user;
      if (this.user) this.load3rdPartyScripts();
    });
  }

  /***************************************************************************/
  /* SETTINGS                                                                */
  /***************************************************************************/

  /**
   * Loads the settings first from the idb to start (for speed)
   * and then the server afterwards (for data integrity)
   */
  private loadSettings() {
    this.applySettings(this.settings);
    this.idb.get(this.table, 'default').then(_settings => {
      if (_settings) this.applySettings(new SettingsModel(_settings));
      this.getSettings().subscribe(_serverSettings => {
        this.applySettings(_serverSettings);
        this.saveSettingsLocal(this.settings, 'default');
      });
    });
  }

  /**
   * Gets the settings from the server, customer optional
   */
  public getSettings(customer?: string): Observable<SettingsModel> {
    return this.GET(this.url, { customerName: customer }).pipe(map(_serverSettings => {
      if (_serverSettings?.home?.homePage && _serverSettings?.theme) {
        return new SettingsModel(_serverSettings);
      } else { //fallback to what we currently have
        return new SettingsModel(this.settings);
      }
    }));
  }

  /**
   * Applies the settings globally to the UI
   */
  private applySettings(settings: SettingsModel) {
    this.settings = settings;
    //HOMEPAGE
    this.setHome(settings.home || new HomeModel({ homePage: HomeEnum.Table, table: new TableModel() }));
    //MENU
    this.setMenu(settings.menu || new MenuModel());
    //THEMING
    this.setBG(true, settings.theme.darkBG || '#000');
    this.setBG(false, settings.theme.lightBG || '#FFF');
    if (this.localStorage.getItem('dark-theme') === 'true') this.setDark(true, false);
    else if (this.localStorage.getItem('dark-theme') === 'false') this.setDark(false, false);
    else if (this.settings.theme.dark === true) this.setDark(true, false);
    else if (this.settings.theme.dark === false) this.setDark(false, false);
    else this.setDark(window.matchMedia("(prefers-color-scheme: dark)").matches, false);
    //set the theme
    this.setToolbar(settings.theme.toolbar || '#000');
    this.setPrimary(settings.theme.primary || '#000');
    this.setAccent(settings.theme.accent || '#7F7F7F');
    this.setWarn(settings.theme.warn || '#F00');
    //KEYS
    this.setKeys(settings.keys);
    //URLS
    this.setUrls(settings.urls);
    //MISC
    this.setMisc(settings.misc);
    //3RD PARTY SCRIPTS
    this.load3rdPartyScripts();
  }

  /**
   * Saves the settings to the local IDB
   */
  private saveSettingsLocal(settings: SettingsModel, key: string) {
    this.idb.save(this.table, key, settings);
  }

  /**
   * Saves the settings to the server, for a specific customer
   * For use in Admin, only by admins
   */
  public saveSettings(settings: SettingsModel, customer: string): Observable<void> {
    return this.PUT(this.url, settings, { customerName: customer }).pipe(map(() => {
      this.saveSettingsLocal(settings, customer);
      if (this.user?.customerName === customer) {
        this.saveSettingsLocal(settings, 'default');
        this.applySettings(settings);
      }
    }));
  }

  /***************************************************************************/
  /* HOMEPAGE                                                                */
  /***************************************************************************/

  /**
   * Sets the home, supplies it to any component that needs to know what the home settings are
   */
  public setHome(home: HomeModel) {
    this.settings.home = home;
    this.home$.next(this.settings?.home);
  }

  /**
   * Sets the homepage, supplies it to any component that needs to know which homepage is currently set
   */
  public setHomePage(homePage: HomeEnum) {
    this.settings.home.homePage = homePage;
    this.home$.next(this.settings?.home);
  }

  /**
   * Gets which homepage is being used as an observable
   */
  public getHome(): Observable<HomeModel> {
    return this.home$.asObservable();
  }

  /***************************************************************************/
  /* MENU                                                                    */
  /***************************************************************************/

  /**
   * Sets the menu, supplies it to any component that needs to know what the menu settings are
   */
  public setMenu(menu: MenuModel) {
    this.settings.menu = menu;
    this.menu$.next(this.settings?.menu);
  }

  /**
   * Gets which configuration for the menu
   */
  public getMenu(): Observable<MenuModel> {
    return this.menu$.asObservable();
  }

  /***************************************************************************/
  /* THEME                                                                   */
  /***************************************************************************/

  /**
   * Returns the theme as an observable so anything can listen for theme changes
   */
  public getTheme(): Observable<ThemeModel> {
    return this.theme$.asObservable();
  }

  /**
   * Sets the dark theme to whatever is supplied.
   */
  public setDark(dark: boolean, save: boolean) {
    this.settings.theme.dark = dark;
    this.theme$.next(this.settings?.theme);
    //toggle dark-theme class if necessary
    if (this.settings?.theme.dark) {
      if (save) this.localStorage.setItem('dark-theme', 'true');
      !document.body.classList.contains('dark-theme') && document.body.classList.add('dark-theme');
      this.setBG(true, this.settings?.theme.darkBG);
    } else {
      if (save) this.localStorage.setItem('dark-theme', 'false');
      document.body.classList.contains('dark-theme') && document.body.classList.remove('dark-theme');
      this.setBG(false, this.settings?.theme.lightBG);
    }
  }

  /**
   * Sets the toolbar color to a simple hex string
   */
  public setToolbar(hex: string) {
    this.settings.theme.toolbar = hex;
    let { r,g,b } = this.hexToRGB(hex);
    let useBlack = ((r * 0.299) + (g * 0.587) + (b * 0.114)) >= 178; //magic numbers
    this.settings.theme.toolbarText = useBlack ? '#000' : '#FFF';
    document.documentElement.style.setProperty(`--toolbar`, this.settings.theme.toolbar);
    document.documentElement.style.setProperty(`--toolbar-text`, this.settings.theme.toolbarText);
  }

  private hexToRGB(hex: string) {
    if (hex[0] === '#')
      hex = hex.substring(1, hex.length);

    if (hex.length === 3)
      hex = hex.replace(/(.)/g, '$1$1');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  }

  /**
   * Generates a primary palette out of a simple hex string
   */
  public setPrimary(hex: string) {
    this.updateTheme(this.computeColors(hex), 'primary');
  }

  /**
   * Generates an accent palette out of a simple hex string
   */
  public setAccent(hex: string) {
    this.updateTheme(this.computeColors(hex), 'accent');
  }

  /**
   * Generates a warning palette out of a simple hex string
   */
  public setWarn(hex: string) {
    this.updateTheme(this.computeColors(hex), 'warn');
  }

  public setBG(dark: boolean, color: string) {
    dark ?
      this.settings.theme.darkBG = color :
      this.settings.theme.lightBG = color;
    if (this.settings.theme.dark === dark) document.documentElement.style.setProperty(`--background`, color);
  }

  /**
   * Updates the css vars that control the theme.
   */
  private updateTheme(colors: Color[], theme: string) {
    colors.forEach(color => {
      document.documentElement.style.setProperty(`--${theme}-${color.name}`, color.hex);
      document.documentElement.style.setProperty(`--${theme}-contrast-${color.name}`, color.darkContrast ? '#000000DE' : 'white');
    });
  }

  /**
   * Computes an entire palette based around a simple hex string
   */
  private computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  /**
   * Uses the tinycolor dependency to generate a color
   */
  private getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }

  /***************************************************************************/
  /* API KEYS                                                                */
  /***************************************************************************/
  private setKeys(keys: KeysModel) {
    this.settings.keys = keys;
    this.keys$.next(this.settings.keys);
  }

  public setKey(key: keyof KeysModel, value: string) {
    this.settings.keys[key] = value;
    this.keys$.next(this.settings.keys);
  }

  public getKeys(): Observable<KeysModel> {
    return this.keys$.asObservable();
  }

  /***************************************************************************/
  /* URLS                                                                    */
  /***************************************************************************/
  private setUrls(urls: UrlsModel) {
    this.settings.urls = urls;
    this.urls$.next(this.settings.urls);
  }

  public setUrl(key: keyof UrlsModel, value: string) {
    this.settings.urls[key] = value;
    this.urls$.next(this.settings.urls);
  }

  public getUrls(): Observable<UrlsModel> {
    return this.urls$.asObservable();
  }

    /***************************************************************************/
  /* URLS                                                                    */
  /***************************************************************************/
  private setMisc(misc: MiscModel) {
    this.settings.misc = misc;
    this.misc$.next(this.settings.misc);
  }

  public getMisc(): Observable<MiscModel> {
    return this.misc$.asObservable();
  }


  /***************************************************************************/
  /* MISC                                                                    */
  /***************************************************************************/

  /** TEMP: remove this later
   * Pops up an alert dialog specifically for UAT
   */
  public notify() {
    if (environment.env === EnvironmentEnum.UAT) {
      let shown = this.localStorage.getItem('uat_alert_shown');
      if (shown) {
        let checked = this.localStorage.getItem('uat_alert_checked');
        let date = new Date(shown);
        date.setMinutes(date.getTimezoneOffset()); //i hate timezones
        date.setHours(0, 0, 0, 0);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let difference = today.getTime() - date.getTime();
        if (difference > 0 && Math.ceil(difference / 86400000) >= (checked ? 7 : 1)) this._notify();
      } else {
        this._notify();
      }
    }
  }

  public _notify() {
    this.dialog.open(AppDialog, {
      ...AppDialogConfig,
      data: {
        type: 'alert',
        title: 'Training Mode',
        message: 'New practice listings entered here will <b>NOT</b> go live. This form is <b>ONLY</b> for training purposes.',
        input: {
          type: 'checkbox',
          label: 'Do not show again for 7 days'
        }
      }
    }).afterClosed().subscribe(checked => {
      if (checked) this.localStorage.setItem('uat_alert_checked', 'true');
      else this.localStorage.removeItem('uat_alert_checked');
      let today = new Date();
      let year = today.getFullYear();
      let month: string | number = today.getMonth() + 1;
      let day: string | number = today.getDate();
      if (month < 10) month = '0' + month;
      if (day < 10) day = '0' + day;
      this.localStorage.setItem('uat_alert_shown', '' +  year + '-' + month + '-' + day);
    });
  }

  /***************************************************************************/
  /* 3RD PARTY SCRIPTS                                                       */
  /***************************************************************************/

  private load3rdPartyScripts() {
    this.loadWalkMe();
  }

  private loadedWalkMe: boolean = false;
  private loadWalkMe() {
    if (!this.loadedWalkMe && this.user) {
      this.loadedWalkMe = true;
      if (
        (this.settings?.urls.walkMe || environment.walkMeUrl) &&
        //make sure it's actually walkme, don't wanna be injecting rando scripts
        (this.settings?.urls.walkMe || environment.walkMeUrl).startsWith('https://cdn.walkme.com/')
      ) {
        //this is a hack to improve loading performance because walkme takes up 1/3 of the CPU time when loading in
        setTimeout(() => {
          let script = document.createElement('script');
          script.src = this.settings?.urls.walkMe || environment.walkMeUrl;
          script.async = true;
          (<any>window)._walkmeConfig = { smartLoad: true };
          (<any>window).__APP__ = {
            user: {
              fullname: this.user.memberFullName,
              email: this.user.memberEmail,
              $gids: { treb: true, agent: true },
              username: this.user.memberKey
            }
          };
          document.head.appendChild(script);
        }, 3000);
      }
    }
  }
}

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}
