import { PublicRecordConfigModel } from "../../form/configs/public-record-config.model";

export class PublicRecordModel extends PublicRecordConfigModel {

  public fields: { name: string, label: string }[] = [
    { name: 'StreetNumber', label: 'Street Number' },
    { name: 'StreetName', label: 'Street Name' }
  ];

  constructor(model?: Partial<PublicRecordModel>) {
    super(model);
    if (model?.fields?.length) {
      this.fields = [ ...model.fields ];
    }
  }
}
