import { LinkModel } from "./menu/link.model";

export class MenuModel {

  public cloneText: string = 'Clone';
  public links: LinkModel[] = [];

  constructor(model?: Partial<MenuModel>) {
    if (model?.cloneText) this.cloneText = model.cloneText
    if (model?.links?.length) this.links = model.links.map(link => new LinkModel(link));
  }
}
