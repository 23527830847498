/**
 * Represents all of the fields that a user should be able to modidfy on a listing.
 * Remember to pull in a new object for each user. Otherwise, persisting is fine enough (for a while).
 */
export class RoleFieldsModel {

  public Property: string[] = [];
  public PropertyRooms: string[] = [];
  public Media: string[] = [];

  // somebody changed the casing on everything as some point,
  // it caused a bug where nobody had permissions to any field
  // so we're just gonna leave both the proper and improper casing in place here
  // which is why you see all the ts-ignores
  constructor(model?: Partial<RoleFieldsModel>) {
    // @ts-ignore
    if (model?.property?.length) this.Property = [...model.property];
    if (model?.Property?.length) this.Property = [...model.Property];
    // @ts-ignore
    if (model?.propertyrooms?.length) this.PropertyRooms = [...model.propertyrooms];
    if (model?.PropertyRooms?.length) this.PropertyRooms = [...model.PropertyRooms];
    // @ts-ignore
    if (model?.media?.length) this.Media = [...model.media];
    if (model?.Media?.length) this.Media = [...model.Media];
  }
}
