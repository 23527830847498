import { exists } from "../../../_helper/util.helper";

/**
 * A configuration for the public record search box.
 */
export class PublicRecordConfigModel {

  public label: string = 'Public Records';

  public provider: 'CRS' | 'Teranet' = 'Teranet';

  //makes all the data from the provider readonly
  public allReadonly: boolean = false;
  public readonly: string[] = [];

  //Some data coming from public records has gotta get hidden cus clients are stupid
  public showHide: boolean = false;
  public showHideMessage: string = 'Use data from...';
  public hide: string[] = [];
  public hideMessage: string = 'Provided by ...?';

  constructor(model?: Partial<PublicRecordConfigModel>) {
    if (model) {
      for (let key in this as PublicRecordConfigModel)
        if (exists(model[key]))
          this[key] = model[key];
      if (model.readonly?.length) this.readonly = [ ...model.readonly ];
      if (model.hide?.length) this.hide = [ ...model.hide ];
    }
  }
}
