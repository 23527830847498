import { TabEnum } from "../../../_enum/settings/tab.enum";
import { MapModel } from "./map.model";
import { PublicRecordModel } from "./public-record-model";
import { TableModel } from "./table.model";

export class TabModel {

  public label: string = null;
  public name: string = null;
  public type: TabEnum = null;
  public table: TableModel = null;
  public map: MapModel = null;
  public publicRecord: PublicRecordModel = null;

  constructor(model?: Partial<TabModel>) {
    if (model?.label) this.label = model.label;
    if (model?.name) this.name = model.name;
    if (model?.type) this.type = model.type;
    if (model?.table) this.table = new TableModel(model.table);
    if (model?.map) this.map = new MapModel(model.map);
    if (model?.publicRecord) this.publicRecord = new PublicRecordModel(model.publicRecord);
  }
}
